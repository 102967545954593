import { subDays, format, differenceInCalendarDays } from 'date-fns';
import getTable from '../helpers/getTable';

const calculateCurrentStreak = async (
  isMounted: boolean,
  setCurrentStreak: React.Dispatch<React.SetStateAction<undefined | number>>,
  user: any
) => {
  try {
    const todayDate = new Date();
    const yesterdayDate = subDays(todayDate, 1);

    const dateFormat = 'yyyy-MM-dd';

    const todayDateString = format(todayDate, dateFormat);
    const yesterdayDateString = format(yesterdayDate, dateFormat);

    const actionsCountByDateTable = getTable(user, 'actionsCountByDate');

    const recentDailyActivityFromAirtable = await actionsCountByDateTable
      .select({
        filterByFormula: `
          AND(
            "Yes" = {Did Meet Goal?},
            OR(
              "${todayDateString}" = DATETIME_FORMAT({date}, "YYYY-MM-DD"),
              "${yesterdayDateString}" = DATETIME_FORMAT({date}, "YYYY-MM-DD")
            )
          )
        `,
        view: 'Grid view',
        fields: ['Date']
      })
      .firstPage();

    if (recentDailyActivityFromAirtable.length === 0) return 0;

    const recentDailyActivity = recentDailyActivityFromAirtable.slice();

    if (typeof recentDailyActivity[0].fields.Date !== 'string') {
      throw new Error("recentDailyActivity[0].fields.Date !== 'string'");
    }

    const endDateString = format(
      new Date(recentDailyActivity[0].fields.Date),
      dateFormat
    );

    const lastMissedDateFromAirtable = await actionsCountByDateTable
      .select({
        filterByFormula: `
          AND(
            "No" = {Did Meet Goal?},
            IS_BEFORE({Date}, "${endDateString}")
          )
        `,
        maxRecords: 1,
        view: 'Grid view',
        fields: ['Date']
      })
      .firstPage();

    if (lastMissedDateFromAirtable.length === 0) {
      const allDates = await actionsCountByDateTable
        .select({
          view: 'Grid view',
          fields: ['Date']
        })
        .firstPage();

      if (endDateString === yesterdayDateString) {
        if (isMounted) {
          setCurrentStreak(allDates.length - 1);
        }
      } else {
        if (isMounted) {
          setCurrentStreak(allDates.length);
        }
      }
    } else {
      const lastMissedDate = lastMissedDateFromAirtable.slice();

      if (typeof lastMissedDate[0].fields.Date !== 'string') {
        throw new Error("lastMissedDate[0].fields.Date !== 'string'");
      }

      const lastMissedDateString = lastMissedDate[0].fields.Date;

      const currentStreak = differenceInCalendarDays(
        new Date(endDateString),
        new Date(lastMissedDateString)
      );

      if (isMounted) {
        setCurrentStreak(currentStreak + 1);
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export default calculateCurrentStreak;
